import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './language.service';
import { FLWR_LANGUAGE } from '../../config';
import { CookieService } from 'ngx-cookie';

@Injectable({
  providedIn: 'root'
})
export class TranslateServiceService {

  translateLoad: boolean;

  constructor(private translateService: TranslateService,
              private languageService: LanguageService,
              private cookieService: CookieService,
              @Inject(PLATFORM_ID) private platformId,) {
  }


  public initAppLanguage() {
    /**
     * setting app default language language
     */
      // first set default language of app
      this.translateService.setDefaultLang('sk');
      // cookie language would set in serverAppInitializerFactory
      let cookieLanguage = this.cookieService.get(FLWR_LANGUAGE);
      // if have cookieLanguage (should have from serverAppInitializerFactory), else set defaultLang
      this.translateService.use(cookieLanguage || this.translateService.defaultLang);
    this.translateLoad = true;
  }
}
