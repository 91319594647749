import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PwaUpdateDialogComponent } from './component/pwa-update-dialog/pwa-update-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SlcButtonModule } from '../../slice-theme/modules/slc-button/slc-button.module';
import { TranslateModule } from '@ngx-translate/core';
import { PwaInstallDialogComponent } from './component/pwa-install-dialog/pwa-install-dialog.component';



@NgModule({
  declarations: [PwaUpdateDialogComponent, PwaInstallDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    SlcButtonModule,
    TranslateModule
  ]
})
export class PwaModule { }
