<div class="row position-relative">
  <div class="col-12">
    <div class="position-absolute close-icon">
      <img (click)="closeModal()" src="assets/icons/app-icons/remove.svg" alt="">
    </div>
    <ng-container *ngIf="data.device === 'android'">
      <div class="pb-3">
        {{ 'modules.pwa.pwaInstallTitle' | translate}}
      </div>
      <div class="text-center">
        <button SlcButton size="sm" color="primary-gradient" (click)="install()"
                [rounded]="true"> {{ 'modules.pwa.install' | translate}}</button>
      </div>
    </ng-container>
    <ng-container *ngIf="data.device === 'ios'">
      <div *ngIf="browser !== 'Safari'; else safari" class="pb-3">
        {{ 'modules.pwa.pwaInstallTitleIosNoSafari' | translate}}
      </div>
      <ng-template #safari>
        <div>
          {{ 'modules.pwa.pwaInstallTitleIosSafari' | translate}}
        </div>
      </ng-template>
    </ng-container>


  </div>
</div>
