import { Directive, HostBinding, Input } from '@angular/core';
import { SlcColor } from '../../types/slcColor';
import { SlcSize } from '../../types/slcSize';

@Directive({
  selector: 'button[SlcButton],a[SlcButton]'
})
export class SlcButtonDirective {

  @Input() color: SlcColor = 'primary'
  @Input() size: SlcSize = 'sm'
  @Input() outline: boolean;
  @Input() rounded: boolean;
  @Input() withIcon: boolean;

  @HostBinding('class.btn-slc')
  get btn() {
    return 'btn'
  }

  @HostBinding('class.btn-lg')
  get getBtnLarge() {
    if (this.size === 'lg') {
      return 'class.btn-lg'
    }
  }


  @HostBinding('class.btn-with-icon')
  get getBtnWithIcon() {
    if (this.withIcon) {
      return 'class.btn-with-icon'
    }
  }

  @HostBinding('class.btn-outline-primary')
  get getOutlinePrimary() {
    if (this.outline && this.color === 'primary') {
      return 'class.btn-outline-primary'
    }
  }

  @HostBinding('class.btn-primary-gradient-outline')
  get getOutlinePrimaryGradient() {
    if (this.outline && this.color === 'primary-gradient') {
      return 'class.btn-outline-primary'
    }
  }

  @HostBinding('class.btn-outline-secondary')
  get getOutlineSecondary() {
    if (this.outline && this.color === 'secondary') {
      return 'class.btn-outline-secondary'
    }
  }

  @HostBinding('class.btn-outline-danger')
  get getOutlineDanger() {
    if (this.outline && this.color === 'danger') {
      return 'class.btn-outline-danger'
    }
  }

  @HostBinding('class.btn-outline-dark')
  get getOutlineDark() {
    if (this.outline && this.color === 'dark') {
      return 'class.btn-outline-dark'
    }
  }

  @HostBinding('class.btn-outline-light')
  get getOutlineLight() {
    if (this.outline && this.color === 'light') {
      return 'class.btn-outline-light'
    }
  }

  @HostBinding('class.btn-outline-success')
  get getOutlineSuccess() {
    if (this.outline && this.color === 'success') {
      return 'class.btn-outline-success'
    }
  }

  @HostBinding('class.btn-outline-warning')
  get getOutlineWarning() {
    if (this.outline && this.color === 'warning') {
      return 'class.btn-outline-warning'
    }
  }


  @HostBinding('class.btn-outline-info')
  get getOutlineInfo() {
    if (this.outline && this.color === 'info') {
      return 'class.btn-outline-info'
    }
  }


  @HostBinding('class.btn-primary')
  get primary() {
    if (!this.outline) {
      return this.color === 'primary';
    }
  }

  @HostBinding('class.btn-primary-gradient')
  get primaryGradient() {
    if (!this.outline) {
      return this.color === 'primary-gradient';
    }
  }

  @HostBinding('class.btn-secondary-gradient')
  get secondaryGradient() {
    if (!this.outline) {
      return this.color === 'secondary-gradient';
    }
  }

  @HostBinding('class.btn-secondary')
  get secondary() {
    if (!this.outline) {
      return this.color === 'secondary';
    }
  }

  @HostBinding('class.btn-success')
  get success() {
    if (!this.outline) {
      return this.color === 'success';
    }
  }

  @HostBinding('class.btn-info')

  get info() {
    if (!this.outline) {

      return this.color === 'info';
    }

  }

  @HostBinding('class.btn-warning')
  get warning() {
    if (!this.outline) {
      return this.color === 'warning';
    }
  }

  @HostBinding('class.btn-danger')
  get danger() {
    if (!this.outline) {
      return this.color === 'danger';
    }
  }

  @HostBinding('class.btn-light-slc')
  get light() {
    if (!this.outline) {
      return this.color === 'light';
    }
  }

  @HostBinding('class.btn-dark')
  get dark() {
    if (!this.outline) {
      return this.color === 'dark';
    }
  }

  @HostBinding('class.btn-round')
  get getRounded() {
    if (this.rounded) {
      return 'btn-round'
    }
  }


  constructor() {
  }

}
