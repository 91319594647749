import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { AuthService } from '../services/auth.service';
import { USER_ROLE } from '../models/user';
import { USER_DATA_STORAGE } from '../../config';
import { CookieService } from 'ngx-cookie';

@Injectable({
  providedIn: 'root'
})
export class B2bGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private authService: AuthService, @Inject(PLATFORM_ID) private platformId, private cookieService: CookieService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.getAccessToken()
      && (isPlatformBrowser(this.platformId) ? localStorage.getItem(USER_DATA_STORAGE) : this.cookieService.get(USER_DATA_STORAGE))
      && (isPlatformBrowser(this.platformId) ? JSON.parse(localStorage.getItem(USER_DATA_STORAGE)).roles.includes(USER_ROLE.ROLE_BUSINESS) : JSON.parse(this.cookieService.get(USER_DATA_STORAGE)).roles.includes(USER_ROLE.ROLE_BUSINESS))) {
      return true;
    }
    this.router.navigate(['/', 'customer'])
    return false
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(childRoute, state);
  }


}
