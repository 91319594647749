import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { catchError, mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class RefreshHandlerInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService,
              private router: Router) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError(err => {
      /*const error = err.error.message || err.statusText;*/
      if (err.status >= 400 && (err.status !== 401)) {
        return throwError(err);
      }
      if (request.url.indexOf('/refresh/customer') > 0 && err.status === 401) {
        this.authService.logOutUser();
        this.router.navigate(['/', 'auth', 'login']);
        return throwError(err);
      }

      if (err.status) {
        if (!this.authService.getRefreshToken()) {
          this.authService.logOutUser();
          this.router.navigate(['/', 'auth', 'login']);
          return throwError(err);
        } else {
          return this.authService.refreshTokens().pipe(
            mergeMap((res) => {
              return next.handle(request);
            })
          );
        }
      }
      return throwError(err);
    }));
  }
}
