import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { LanguageModel } from '../models/language';
import { environment } from '../../../environments/environment';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})


export class LanguageService {

  constructor(private http: HttpClient) {
  }

  public languages$ =  new BehaviorSubject<LanguageModel[]>(null);

  public getLanguages(): Observable<LanguageModel[]> {
    return this.http.get<LanguageModel[]>(`${environment.backendUrl}/enum/language`).pipe(tap(res => {
      this.languages$.next(res);
    }))

  }
}
