import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Platform } from '@angular/cdk/platform';

export enum KEYBOARD_VISIBILITY {
  VISIBLE = 'VISIBLE',
  INVISIBLE = 'INVISIBLE'
}


@Injectable(
  {providedIn: 'root'}
)
export class KeyboardVisibilityService {
  public allowedElements = ['INPUT', 'TEXTAREA'];
  public keyboardIsVisible: boolean = false;
  // heights
  public realDeviceHeight: number;
  public currentHeight: number;

  public focusedElement;

  public keyboardVisibility: BehaviorSubject<KEYBOARD_VISIBILITY> = new BehaviorSubject<KEYBOARD_VISIBILITY>(KEYBOARD_VISIBILITY.INVISIBLE);
  public resizeSubject: Subject<{ height: number, width: number }> = new Subject<{ height: number, width: number }>();

  public afterFocusInterval;
  public afterFocusTimeInSeconds: number;

  public resizeSubs = [];

  constructor(@Inject(PLATFORM_ID) private platformId,
              private platform: Platform) {
  }

  /**
   * init every feature needed to checking keyboard visibility
   */
  public initKeyBoardVisibilityChecker() {
    this.initResizeListener();
    this.realDeviceHeight = window.innerHeight;
    this.initFocusInListener();
    this.initFocusOutListener();
    this.currentHeightChangerSubscribe();
  }

  /**
   * init focus in listener to specific type of element = INPUT and TEXTAREA
   * @private
   */
  private initFocusInListener() {
    document.addEventListener('focusin', (event) => {
      if (this.allowedElements.indexOf(event.target['nodeName']) !== -1) {
        // start counting down to check if height is changing "immediately" after focus
        this.afterFocusStartInterval(3);
      }
    })
  }

  /**
   *
   *
   * init focus out listener to specific type of element = INPUT and TEXTAREA
   * @private
   */
  private initFocusOutListener() {
    document.addEventListener('focusout', (event) => {
      if (this.allowedElements.indexOf(event.target['nodeName']) !== -1) {
        this.afterFocusOutStopInterval();
      }
    })
  }

  /**
   * init resize window listener to detect every window resize -> also when virtual keyboard appear
   * @private
   */
  private initResizeListener() {
    window.addEventListener('resize', (event) => {
      this.resizeSubject.next({height: event.target['innerHeight'], width: event.target['innerWidth']})
    })
  }


  /**
   * Subscribe to resize subject
   * handle keyboard visibility depend on few conditions
   *
   * @private
   */
  private currentHeightChangerSubscribe() {
    if (this.resizeSubs.length <= 0) {
      this.resizeSubs.push(this.resizeSubject.subscribe(
        res => {
          this.currentHeight = res.height;
          // when keyboard was not visible
          // and focus time is higher than 0, it means that is "immediately" after focus event
          // and real device height is bigger than current height
          // we assume that visual keyboard is appeared
          if (!this.keyboardIsVisible && this.afterFocusTimeInSeconds > 0 && this.realDeviceHeight > this.currentHeight) {
            this.keyboardIsVisible = true;
            this.changeKeyboardVisibilityStatus(KEYBOARD_VISIBILITY.VISIBLE);


            this.focusedElement = document.activeElement;
            // when keyboard was visible
            // and currentHeight is equal to real device height
            // we assume that keyboard is disappeared
          } else if (this.keyboardIsVisible && this.realDeviceHeight === this.currentHeight) {
            this.keyboardIsVisible = false;
            // if keyboard is disappeared f.e. by backButton and not blur input, we force blur to unfocus
            if (document.activeElement && this.focusedElement && document.activeElement === this.focusedElement) {
              // @ts-ignore
              document.activeElement.blur();
              this.focusedElement = null;
            }
            this.realDeviceHeight = this.currentHeight;
            this.changeKeyboardVisibilityStatus(KEYBOARD_VISIBILITY.INVISIBLE);
            // if happened resize changes and no above conditions
            // we assumed that  window resized in some another way and its current height is height of device
          } else {
            this.realDeviceHeight = this.currentHeight;
          }
        }
      ))
    }
  }

  /**
   * change keyboard visibility status
   * @param visibility: visible or invisible
   */
  public changeKeyboardVisibilityStatus(visibility: KEYBOARD_VISIBILITY) {
    this.keyboardVisibility.next(visibility);
  }


  /**
   * start counting
   *
   * @param afterFocusTime: time in seconds,  which is acceptable for window resize (after focus event) to mark as keyboard appearance
   */
  public afterFocusStartInterval(afterFocusTime) {
    if (this.afterFocusInterval) {
      clearInterval(this.afterFocusInterval);
    }
    this.afterFocusTimeInSeconds = afterFocusTime;
    this.afterFocusInterval = setInterval(() => {
      this.afterFocusTimeInSeconds -= 1;
      if (this.afterFocusTimeInSeconds <= 0) {
        clearInterval(this.afterFocusInterval);
      }
    }, 1000)
  }

  /**
   * stop after focus interval and set afterFocus time
   */
  afterFocusOutStopInterval() {
    if (this.afterFocusInterval) {
      clearInterval(this.afterFocusInterval)
    }
    this.afterFocusTimeInSeconds = 0;
  }


}
