import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { MatDialog } from '@angular/material/dialog';
import { PwaInstallDialogComponent } from '../component/pwa-install-dialog/pwa-install-dialog.component';
import { isPlatformBrowser } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class PwaInstallService {

  constructor(private platform: Platform,
              private dialogService: MatDialog,
              @Inject(PLATFORM_ID) private platformId,
  ) {
  }

  public initAppInstallPrompt() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.platform.ANDROID) {
        window.addEventListener('beforeinstaprillprompt', (event: any) => {
          event.preventDefault();
          const dialog = this.dialogService.open(PwaInstallDialogComponent,
            {
              hasBackdrop: false,
              position: {bottom: '15px', right: '15px'},
              data: {device: 'android'}
            }).afterClosed().subscribe(
            res => {
              if (res) {
                event.prompt();
              }
            }
          );
        });
      }
      if (this.platform.IOS) {
        if (!this.isInStandaloneMode()) {
          this.dialogService.open(PwaInstallDialogComponent,
            {hasBackdrop: false, position: {bottom: '15px', right: '15px'}, data: {device: 'ios'}});
        }
      }
    }
  }

  isInStandaloneMode(): boolean {
    // @ts-ignore
    return ('standalone' in window.navigator) && (window.navigator.standalone);
  }
}
