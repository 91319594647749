import { CurrencyModel } from './shared/models/currency';
import { Moment } from 'moment/moment';

export const JWT_ACCESS_STORAGE = 'e-kvety-access-token';
export const JWT_REFRESH_TOKEN_STORAGE = 'e-kvety-refresh-token';
export const USER_DATA_STORAGE = 'e-kvety-user';
export const FLWR_LANGUAGE = 'e-kvety-language';
export const FLWR_CUSTOMER_CART = 'e-kvety-customer-cart';
export const FLWR_B2B_CUSTOMER_CART = 'e-kvety-customer-cart-b2b';
export const FLWR_FIRST_ENTRY = 'e-kvety-first-entry';


export const PATTERNS = {
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  password: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=\S+$).{8,}$/
};

export const euroCurrency: CurrencyModel = {
  symbol: '€',
  code: 'eur',
  name: 'euro',
  proportion: 100
};

export const cookiesOption = (actualTime) => {
  return {
    path: '/',
    expires: actualTime.add(1, 'y').toISOString()
  };
};

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

export const MY_DATE_FORMATS_WITHOUT_YEAR = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

export const NO_PADDING_DIALOG = {
  panelClass: 'no-padding-dialog',
  width: '90%',
  maxWidth: '500px',
  minHeight: '150px',
};

export const newCardPaymentIntent = 'newCardPaymentIntent';
export const payLateIntent = 'payLateIntent';

export const CHANGE_BOUQUET_DIALOG_OPTIONS = {
  width: '90%',
  maxWidth: '500px',
  maxHeight: '500px',
  minHeight: '150px',
};
