import { Directive, HostBinding, Input } from '@angular/core';
import { SlcColor } from '../../../types/slcColor';

@Directive({
  selector: '[SlcIconInputWrapper]'
})
export class SlcIconInputWrapperDirective {

  @Input() color: SlcColor = 'dark';
  @Input() rounded: boolean = false;

  @HostBinding('class.icon-input-wrapper')
  get getIconInputWrapper() {
    return 'icon-input-wrapper'
  }

  @HostBinding('class.input-wrapper-dark')
  get getInputWrapperDark() {
    if (this.color === 'dark')
      return 'input-wrapper-dark'
  }

  @HostBinding('class.input-wrapper-light')
  get getInputWrapperLight() {
    if (this.color === 'light')
      return 'input-wrapper-light'
  }

  @HostBinding('class.input-wrapper-rounded')
  get getInputWrapperRounded() {
    if (this.rounded)
      return 'input-wrapper-rounded'
  }


  constructor() {
  }

}
