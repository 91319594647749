import { BrowserModule, BrowserTransferStateModule, TransferState } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SlcButtonModule } from './slice-theme/modules/slc-button/slc-button.module';
import { SlcInputModule } from './slice-theme/modules/slc-input/slc-input.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { RefreshHandlerInterceptor } from './shared/interceptors/refresh-handler.interceptor';
import { JwtInterceptor } from './shared/interceptors/jwt.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { ToastHandlerInterceptor } from './shared/interceptors/toast-handler.interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { translateBrowserLoaderFactory } from './translate.browser.loader';
import { SWIPER_CONFIG, SwiperConfigInterface, SwiperModule } from 'ngx-swiper-wrapper';
import { NgxStripeModule } from 'ngx-stripe';
import { TransferHttpCacheModule } from '@nguniversal/common';
import {
  FacebookLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule
} from 'angularx-social-login';
import { MatDialogModule } from '@angular/material/dialog';
import { PwaModule } from './external-modules/pwa/pwa.module';
import { CookieModule } from 'ngx-cookie';


const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
  navigation: true,
  pagination: true,
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    TransferHttpCacheModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SlcButtonModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    SlcInputModule,
    BrowserTransferStateModule,
    FontAwesomeModule,
    SwiperModule,
    SocialLoginModule,
    MatDialogModule,
    PwaModule,
    CookieModule.forRoot(),
    NgxStripeModule.forRoot(environment.stripePublicKey),
    ToastrModule.forRoot({
      progressBar: true,
      progressAnimation: 'decreasing',
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState]
      }
    }),
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: RefreshHandlerInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ToastHandlerInterceptor, multi: true},
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebookProviderKey)
          }
        ]
      } as SocialAuthServiceConfig,
    }],

  bootstrap: [AppComponent]
})
export class AppModule {
}
