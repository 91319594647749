import { AfterViewInit, Component, HostListener, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { TranslateServiceService } from './shared/services/translate-service.service';
import { AuthService } from './shared/services/auth.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { PwaUpdateService } from './external-modules/pwa/services/pwa-update.service';
import { PwaInstallService } from './external-modules/pwa/services/pwa-install.service';
import { KeyboardVisibilityService } from './shared/services/keyboard-visibility.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'e-kvety';
  faUser = faUser;

  constructor(private translateServiceService: TranslateServiceService,
              private authService: AuthService,
              private pwaUpdateService: PwaUpdateService,
              private pwaInstallService: PwaInstallService,
              private renderer: Renderer2,
              @Inject(DOCUMENT) private _document,
              private keyboardVisibilityService: KeyboardVisibilityService,
              @Inject(PLATFORM_ID) private platformId) {
    if (isPlatformBrowser(this.platformId)) {
      this.injectScripts();
    }
  }

  ngOnInit(): void {
    // check if user is logged
    if (isPlatformBrowser(this.platformId)) {
      this.authService.initUser();
      this.translateServiceService.initAppLanguage();
      this.keyboardVisibilityService.initKeyBoardVisibilityChecker();
    }
  }

  ngAfterViewInit(): void {
    this.pwaInstallService.initAppInstallPrompt();
  }

  /**
   * init google analytics script
   */
  injectScripts(): void {
    if (environment.production) {
      const gtmScriptTag = this.renderer.createElement('script');
      gtmScriptTag.type = 'text/javascript';
      gtmScriptTag.src = 'https://www.googletagmanager.com/gtag/js?id=G-D6Z35X6HQQ';
      this.renderer.appendChild(this._document.head, gtmScriptTag);


      const gtagInitScript = this.renderer.createElement('script');
      gtagInitScript.type = 'text/javascript';
      gtagInitScript.text =
        `  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-D6Z35X6HQQ');`;
      this.renderer.appendChild(this._document.head, gtagInitScript);
    }
  }
}
