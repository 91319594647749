import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  JWT_REFRESH_TOKEN_STORAGE,
  JWT_ACCESS_STORAGE,
  USER_DATA_STORAGE,
  cookiesOption,
  FLWR_CUSTOMER_CART,
  FLWR_B2B_CUSTOMER_CART
} from '../../config';
import { UserModel } from '../models/user';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { BillingAddressModel } from '../models/address';
import { CreditCardModel } from '../models/creditCard';
import { isPlatformBrowser } from '@angular/common';
import { CookieService } from 'ngx-cookie';
import * as moment from 'moment';

export interface UserResponse {
  refreshToken: string;
  accessToken: string;
  customer: UserModel;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public user$: BehaviorSubject<UserModel> = new BehaviorSubject<UserModel>(null);


  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId) {
  }

  /**
   *
   * Set access and refresh token
   * set user default from local storage, if no user in LS set as undefined
   */
  public initUser() {
    this.user$.next(localStorage.getItem(USER_DATA_STORAGE) ? JSON.parse(localStorage.getItem(USER_DATA_STORAGE))
      : null);
  }

  /**
   * Log into app
   * @param email
   * @param password
   */
  public logIn(email: string, password: string): Observable<UserResponse> {
    return this.http.post<UserResponse>(`${environment.backendUrl}/login/customer`,
      {email, password}).pipe(tap(
      response => {
        this.removeCartsFromLocalStorage();
        this.updateUserDataWithTokens(response.customer,
          response.accessToken,
          response.refreshToken);
      }
    ));
  }

  /**
   * request company from outside to owner in order to register in app
   *
   * @param email: email to contact person
   * @param phoneNumber: Phone number to contact person
   * @param company: company name of requested company
   */
  public requestCompanyToOwner(email, phoneNumber, company) {
    return this.http.post<UserResponse>(`${environment.backendUrl}/company-request`,
      {email, phoneNumber, company});
  }

  /**
   * get customer data from BE and customer  in app
   */
  public getUserMe() {
    this.http.get<UserModel>(`${environment.backendUrl}/customer/account`,).subscribe(
      user => {
        this.updateUserData(user);
      }
    );
  }

  /**
   * change customer password
   */
  public changeUserPassword(oldPassword, newPassword): Observable<any> {
    return this.http.put(`${environment.backendUrl}/customer/account/change-password`, {oldPassword, newPassword});
  }

  /**
   * update customer data
   */
  public updateUser(name: string, surname: string, billingAddress: BillingAddressModel): Observable<UserModel> {
    return this.http.put<UserModel>(`${environment.backendUrl}/customer/account`, {
      name,
      surname,
      billingAddress
    }).pipe(tap((user: UserModel) => {
      this.updateUserData(user);
    }));
  }

  public loginWithFacebook(token): Observable<UserResponse | { name: string, surname: string, token: string } | any> {
    return this.http.post<UserResponse | { name: string, surname: string, token: string } | any>(`${environment.backendUrl}/login/customer/facebook`, {token}).pipe(tap(
      response => {
        this.removeCartsFromLocalStorage();
        if (response.accessToken && response.refreshToken) {
          this.updateUserDataWithTokens(response.customer,
            response.accessToken,
            response.refreshToken);
        }
      }
    ));
  }

  public loginWithGoogle(token): Observable<UserResponse | { name: string, surname: string, token: string } | any> {
    return this.http.post<UserResponse | { name: string, surname: string, token: string } | any>(`${environment.backendUrl}/login/customer/google`, {token}).pipe(tap(
      response => {
        this.removeCartsFromLocalStorage();
        if (response.accessToken && response.refreshToken) {
          this.updateUserDataWithTokens(response.customer,
            response.accessToken,
            response.refreshToken);
        }
      }
    ));
  }


  /**
   * first step of registration, sending email to verification
   *
   * @param email: email from form
   * @param password: password from form
   * @param language: language from form
   */
  public registerInit(email, password, language): Observable<any> {
    return this.http.post(`${environment.backendUrl}/register/init`,
      {email, password, language});
  }

  /**
   * second step of registration, user came from email and putting phone and name and surname to continue registration
   *
   * @param token: token from BE to validate user
   * @param phoneNumber: user phone  number form form
   * @param name: user name from form
   * @param surname: user surname from Form
   * @param billingAddress: address type of BillingAddress
   */
  public registerDataPhoneNumber(token, phoneNumber, name, surname, billingAddress: BillingAddressModel): Observable<any> {
    return this.http.post(`${environment.backendUrl}/register/data`,
      {token, phoneNumber, name, surname, billingAddress});
  }

  /**
   * last step of registration
   * user got verification code by sms, after right code registration successful
   * @param token: token remembered from previous reg. step
   * @param verificationCode: verify code from sms
   */
  public registerVerificationCode(token, verificationCode): Observable<UserResponse> {
    return this.http.post<UserResponse>(`${environment.backendUrl}/register/phone-number`,
      {token, verificationCode}).pipe(
      tap(res => {
        this.removeCartsFromLocalStorage();
        this.updateUserDataWithTokens(res.customer, res.accessToken, res.refreshToken);
      })
    );
  }

  /**
   * request to reset password
   * @param email: use email to send link to set new password
   */
  public resetPasswordRequest(email): Observable<any> {
    return this.http.post<UserResponse>(`${environment.backendUrl}/password-reset/customer/request`,
      {email});
  }

  /**
   *
   * @param token
   * @param newPassword
   */
  public setPasswordNewUserFromAdmin(token, newPassword): Observable<UserResponse> {
    return this.http.post<UserResponse>(`${environment.backendUrl}/password-setting/customer`,
      {token, newPassword}).pipe(
      tap(res => {
        this.removeCartsFromLocalStorage();
        this.updateUserDataWithTokens(res.customer, res.accessToken, res.refreshToken);
      })
    );
  }

  /**
   * set new password
   * @param token
   * @param newPassword
   */

  public setNewPassword(token, newPassword): Observable<UserResponse> {
    return this.http.post<UserResponse>(`${environment.backendUrl}/password-reset/customer/confirm`,
      {token, newPassword}).pipe(
      tap(res => {
        this.removeCartsFromLocalStorage();
        this.updateUserDataWithTokens(res.customer, res.accessToken, res.refreshToken);
      })
    );
  }

  /**
   * update access token in app
   * @param newToken: new accessToken
   */
  private updateAccessToken(newToken: string) {
    localStorage.setItem(JWT_ACCESS_STORAGE, newToken);
    this.cookieService.put(JWT_ACCESS_STORAGE, newToken, cookiesOption(moment()));
  }

  /**
   * update refreshToken in app
   * @param newToken: new refreshToken
   */
  private updateRefreshToken(newToken: string) {
    localStorage.setItem(JWT_REFRESH_TOKEN_STORAGE, newToken);
    this.cookieService.put(JWT_REFRESH_TOKEN_STORAGE, newToken, cookiesOption(moment()));
  }

  /**
   * Update user data in app
   * @param user: new user
   */
  private updateUserData(user: UserModel) {
    this.user$.next(user);
    localStorage.setItem(USER_DATA_STORAGE, JSON.stringify(user));
    this.cookieService.put(USER_DATA_STORAGE, JSON.stringify(user), cookiesOption(moment()));
  }

  /**
   * Update user information and tokens
   *
   * @param user: user data, UserModel
   * @param accessToken: accessToken
   * @param refreshToken: refreshToken
   */
  private updateUserDataWithTokens(user, accessToken, refreshToken) {
    this.updateUserData(user);
    this.updateAccessToken(accessToken);
    this.updateRefreshToken(refreshToken);
  }

  /**
   * logout user from app
   */
  public logOutUser() {
    this.removeUserTokensAndCartsFromLocalStorage();
    this.user$.next(null);
  }

  /**
   * refresh Tokens in app
   */
  public refreshTokens(): Observable<UserResponse> {
    return this.http.post<UserResponse>(`${environment.backendUrl}/refresh/customer`, {
      refreshToken: this.getRefreshToken()
    }).pipe(
      // map((res: any) => res.data),
      tap((res) => {
        this.updateUserDataWithTokens(res.customer, res.accessToken, res.refreshToken);
      }));
  }


  /**
   * get access token from app
   */
  getAccessToken() {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem(JWT_ACCESS_STORAGE);
    } else {
      return this.cookieService.get(JWT_ACCESS_STORAGE);
    }
  }

  /**
   * get refresh token from app
   */
  public getRefreshToken() {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem(JWT_REFRESH_TOKEN_STORAGE);
    } else {
      return this.cookieService.get(JWT_REFRESH_TOKEN_STORAGE);
    }
  }


  /**
   * get user credits card
   */

  public getCustomerCreditCards(): Observable<CreditCardModel[]> {
    return this.http.get<CreditCardModel[]>(`${environment.backendUrl}/customer/account/credit-card`);
  }

  /**
   * delete user credits card
   */
  public deleteCustomerCreditCard(paymentMethodId): Observable<any> {
    return this.http.request<any>('delete', `${environment.backendUrl}/customer/account/credit-card`, {body: {paymentMethodId}});
  }

  /**
   * check if user is logged in app
   */
  public isUserLogged() {
    return !!this.user$.getValue();
  }


  /**
   * remove user data , Tokens, And Carts from localstorage
   */

  public removeUserTokensAndCartsFromLocalStorage(): void {
    localStorage.removeItem(JWT_ACCESS_STORAGE);
    localStorage.removeItem(JWT_REFRESH_TOKEN_STORAGE);
    localStorage.removeItem(USER_DATA_STORAGE);
    this.cookieService.remove(JWT_ACCESS_STORAGE);
    this.cookieService.remove(JWT_REFRESH_TOKEN_STORAGE);
    this.cookieService.remove(USER_DATA_STORAGE);
    this.removeCartsFromLocalStorage();
  }


  /**
   * remove carts from localStorage
   */
  public removeCartsFromLocalStorage(): void {
    localStorage.removeItem(FLWR_CUSTOMER_CART);
    localStorage.removeItem(FLWR_B2B_CUSTOMER_CART);
  }


}
