import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlcInputDirective } from './directives/slc-input.directive';
import { SlcIconInputWrapperDirective } from './directives/slc-icon-input-wrapper.directive';


@NgModule({
  declarations: [SlcInputDirective, SlcIconInputWrapperDirective],
    exports: [
        SlcInputDirective,
        SlcIconInputWrapperDirective,
    ],
  imports: [
    CommonModule
  ]
})
export class SlcInputModule { }
