import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { B2bGuard } from './shared/guards/b2b.guard';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth-module/auth-module.module').then(m => m.AuthModuleModule),
  }, {
    path: 'customer',
    loadChildren: () => import('./modules/customer-module/customer-module.module').then(m => m.CustomerModuleModule),
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () => import('./modules/terms-and-conditions/terms-and-conditions.module').then(m => m.TermsAndConditionsModule),
  },
  {
    path: 'b2b',
    loadChildren: () => import('./modules/b2b-module/b2b.module').then(m => m.B2bModule),
    canActivate: [B2bGuard],
    canActivateChild: [B2bGuard],
  },
  {
    path: '**',
    loadChildren: () => import('./external-modules/not-found/not-found.module').then(m => m.NotFoundModule),
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
  })],

  exports: [RouterModule]
})
export class AppRoutingModule {
}
