import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { MatDialog } from '@angular/material/dialog';
import { PwaUpdateDialogComponent } from '../component/pwa-update-dialog/pwa-update-dialog.component';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PwaUpdateService {

  constructor(private readonly swUpdate: SwUpdate,
              private dialog: MatDialog,
              @Inject(PLATFORM_ID) private platformId) {
    if (isPlatformBrowser(this.platformId)) {
      if (this.swUpdate.isEnabled) {
        this.swUpdate.checkForUpdate().then(
          res => {
          }
        )
        this.swUpdate.available.subscribe(
          res => {
            this.dialog.open(PwaUpdateDialogComponent, {
              hasBackdrop: false,
              position: {bottom: '15px', right: '15px'},
            }).afterClosed().subscribe(
              res => {
                if (res) {
                  this.swUpdate.activateUpdate().then(
                    activateRes => {
                      window.location.reload()
                    }
                  )
                }
              }
            );
          }
        )
      }
    }
  }
}
