import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'flwr-pwa-install-dialog',
  templateUrl: './pwa-install-dialog.component.html',
  styleUrls: ['./pwa-install-dialog.component.scss']
})
export class PwaInstallDialogComponent implements OnInit {
  browser = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: { device: 'android' | 'ios' },
              private dialogRef: MatDialogRef<PwaInstallDialogComponent>) {
  }

  ngOnInit(): void {
    this.getBrowser();
  }

  closeModal() {
    this.dialogRef.close(false)
  }

  install() {
    this.dialogRef.close(true)
  }

  getBrowser() {
    if (navigator.userAgent.toLowerCase().indexOf('safari') > -1) {
      this.browser = 'Safari';
    }
  }
}
