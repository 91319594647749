import { LanguageModel } from './language';
import { CompanyModel } from './company';

export interface UserModel {
  publicId: string;
  email: string;
  name: string;
  surname: string;
  phoneNumber: PhoneNumberModel;
  language: LanguageModel;
  isCompany: boolean;
  isActive: boolean;
  created: string;
  billingAddress: AddressModel;
  company: CompanyModel;
  roles: USER_ROLE[];
}

export enum USER_ROLE {
  ROLE_CUSTOMER = 'ROLE_CUSTOMER',
  ROLE_BUSINESS = 'ROLE_BUSINESS'
}

export interface PhoneNumberModel {
  countryCode: string;
  dialCode: string;
  e164Number: string;
  internationalNumber: string;
  nationalNumber: string;
  number: string;
}

export interface AddressModel {
  street: string;
  houseNumber: string;
  city: string;
  zipCode: string;
  country: string;
  addressGoogle?: string;
}
