<div class="row position-relative">
  <div class="col-12">
    <div class="position-absolute close-icon">
      <img (click)="closeModal()" src="assets/icons/app-icons/remove.svg" alt="">
    </div>
    <div class="pb-3">
      {{ 'modules.pwa.pwaUpdateTitle' | translate}}
    </div>
    <div class="text-center">
      <button SlcButton size="sm" color="primary-gradient" (click)="update()" [rounded]="true"> {{ 'modules.pwa.update' | translate}}</button>
    </div>
  </div>
</div>
