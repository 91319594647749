import { Directive, HostBinding, Input } from '@angular/core';
import { SlcColor } from '../../../types/slcColor';

export type SlcInputType = 'border' | 'border-less'

@Directive({
  selector: 'input[SlcInput]'
})
export class SlcInputDirective {

  @Input() inputType: SlcInputType = 'border';
  @Input() color: SlcColor;
  @Input() rounded: boolean = false;
  @Input() isPasswordInput: boolean;

  constructor() {
  }

  @HostBinding('class.slc-input')
  get getSlcInput() {
    return 'class.slc-input';
  }
  @HostBinding('class.password-input-padding-right')
  get getIsPasswordInput() {
    if (this.isPasswordInput) {
      return 'class.password-input-padding-right';
    }
  }

  @HostBinding('class.form-control-border-less')
  get getBorderLess() {
    return this.inputType === 'border-less';
  }

  @HostBinding('class.form-control')
  get getFromControl() {
    return 'class.form-control';
  }

  @HostBinding('class.input-rounded')
  get getInputWrapperRounded() {
    if (this.rounded) {
      return 'input-rounded';
    }
  }


}
