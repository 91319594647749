import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ToastHandlerService {

  constructor(private toastrService: ToastrService,
              private translateService: TranslateService) {
  }

  /**
   * show main response error and all validation errors
   * @param error http error
   */
  public showErrorToastsResponse(error: HttpErrorResponse): any {
    if (error.error && error.error.errors) {
      const validateErrors = Object.keys(error.error.errors);
      const objectOfValidateErrors = {};
      for (const validateError of validateErrors) {
        if (Array.isArray(error.error.errors[validateError])) {
          error.error.errors[validateError].forEach(
            singleError => {
              this.toastrService.error(singleError)
            }
          )
          objectOfValidateErrors[validateError] = error[validateError][0];
        } else {
          this.toastrService.error(this.translateService.instant(`be.error.${error.error.errors[validateError].code}`))
        }
      }
    } else {
      this.handleClassicError(error);
    }
  }

  /**
   * handle classic errors from be
   * @param error
   *
   */
  private handleClassicError(error) {
    if (error.error.code) {
      this.toastrService.error(this.translateService.instant(`be.error.${error.error.code}`));
    } else {
      this.toastrService.error(error.error.message);
    }
  }


  /**
   * Show success response backend message
   */
  public showSuccessToastsResponse(): any {
    this.toastrService.success(
      this.translateService.instant('common.toast.successful')
    ).onTap.pipe(take(1)).subscribe(
      res => {
      }
    );
  }
}
