import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'flwr-pwa-update-dialog',
  templateUrl: './pwa-update-dialog.component.html',
  styleUrls: ['./pwa-update-dialog.component.scss']
})
export class PwaUpdateDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<PwaUpdateDialogComponent>) {
  }

  ngOnInit(): void {
  }

  update() {
    this.dialogRef.close(true);
  }

  closeModal() {
    this.dialogRef.close(false);
  }
}
