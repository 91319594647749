import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlcButtonDirective } from './slc-button.directive';


@NgModule({
  declarations: [SlcButtonDirective],
  imports: [
    CommonModule
  ], exports: [
    SlcButtonDirective
  ]
})
export class SlcButtonModule {
}
