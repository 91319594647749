import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ToastHandlerService } from '../services/toast-handler.service';

export const SkipToastInterceptor = 'skip-toast';

@Injectable()
export class ToastHandlerInterceptor implements HttpInterceptor {

  constructor(private toastHandlerService: ToastHandlerService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.headers.has(SkipToastInterceptor)) {
      const headers = request.headers.delete(SkipToastInterceptor);
      return next.handle(request.clone({headers}));
    }
    return next.handle(request).pipe(
      map(event => {
        if (event instanceof HttpResponse) {
          if (request.method === 'PUT' || request.method === 'DELETE' || request.method === 'POST') {
            if (request.url.includes('/refresh/customer')) {
              return event;
            } else {
              if (event.status !== 204) {
                this.toastHandlerService.showSuccessToastsResponse();
              }
            }
          }
        }
        return event;
      }),

      catchError(err => {
        if (err.error.code !== 1004) {
          this.toastHandlerService.showErrorToastsResponse(err);
        }
        return throwError(err);
      })
    );
  }
}
